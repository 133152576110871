import { useState } from "react";
import styles from "./Components.module.scss";
import { PolicyDetails } from "./policyDetails";

export const PolicyBtn = (props) => {
	const [policyOpen, setPolicyOpen] = useState(true);
	const openPolicy = (e) => {
		e.preventDefault();
		setPolicyOpen(true);
	};
	const closePolicy = (e) => {
		e.preventDefault();
		setPolicyOpen(false);
		document.querySelector("body").classList.remove("modalOpen");
	};
	return (
		<>
			<a href="#" onClick={openPolicy} className={styles.policyBtn}>
				<svg
					viewBox="65 65 894 894"
					className="question_circle"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 708c-22.1 0-40-17.9-40-40s17.9-40 40-40 40 17.9 40 40-17.9 40-40 40zm62.9-219.5a48.3 48.3 0 0 0-30.9 44.8V620c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-21.5c0-23.1 6.7-45.9 19.9-64.9 12.9-18.6 30.9-32.8 52.1-40.9 34-13.1 56-41.6 56-72.7 0-44.1-43.1-80-96-80s-96 35.9-96 80v7.6c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V420c0-39.3 17.2-76 48.4-103.3C430.4 290.4 470 276 512 276s81.6 14.5 111.6 40.7C654.8 344 672 380.7 672 420c0 57.8-38.1 109.8-97.1 132.5z"></path>
				</svg>
			</a>
			{policyOpen ? (
				<PolicyDetails close={closePolicy} data={props?.data} />
			) : (
				""
			)}
		</>
	);
};
