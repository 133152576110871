import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Components.module.scss";

export const Modal = (props) => {
	const [modalOpen, setModalOpen] = useState(false);
	let navigate = useNavigate();
	const closeFunction = (e) => {
		e.preventDefault();
		setModalOpen(false);
		document.querySelector("body").classList.remove("modalOpen");
		navigate("..", { replace: true });
	};
	if (!modalOpen) {
		document.querySelector("body").classList.add("modalOpen");
		setModalOpen(true);
	}

	const close = props?.close || closeFunction;
	return (
		<div className={styles.modal}>
			<article>
				<header>
					<h2>{props.headline}</h2>
					<a href="#" onClick={close}>
						X
					</a>
				</header>
				{props.children}
			</article>
		</div>
	);
};
