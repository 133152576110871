import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import { Grid } from "./Components/grid";
import { Success } from "./Components/success";

function App() {
	const [data, setData] = useState([]);
	const url = "https://hyttesalg.techcollege.dk/Umbraco/api/hytter/get";
	useEffect(async () => {
		await setData(await myFetch(url));
	}, []);
	return (
		<Router>
			<Routes>
				<Route path="/success" element={<Success data={data} />} />
				<Route path="*" element={<Grid data={data} />} />
			</Routes>
		</Router>
	);
}

const myFetch = async (url) => {
	const unresolved = await fetch(url);
	const resolved = await unresolved.json();
	//console.log(resolved);
	return resolved;
};

export default App;
