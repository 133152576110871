import { Route, Routes } from "react-router-dom";
import { Form } from "./form";
import { HutDetails } from "./hutDetails";
import { HutPreview } from "./hutPreview";

import styles from "./Components.module.scss";
import { PolicyBtn } from "./policyBtn";
import { HutReserved } from "./hutReserved";

export const Grid = (props) => {
	return (
		<>
			<Routes>
				<Route
					path="/details/:id"
					element={<HutDetails data={props.data.items} />}
				/>
				<Route
					path="/details/:id/reserver"
					element={<Form data={props.data.items} />}
				/>
			</Routes>
			<img src="/images/techcollege.svg" alt="" className={styles.logo} />
			<p className={styles.intro_txt}>
				Her på siden kan du orientere dig om, hvilke udhuse, skure og
				byggeprojekter der er sat til salg.
			</p>
			<div className={styles.grid}>
				{props?.data?.items
					?.sort((a, b) => compare(a, b))
					.map((item, index) => {
						return !item?.is_reserved ? (
							<HutPreview key={index} data={item} />
						) : (
							<HutReserved key={index} data={item} />
						);
					})}
			</div>
			{props.data ? <PolicyBtn data={props?.data} /> : ""}
		</>
	);
};

function compare(a, b) {
	if (a.is_reserved < b.is_reserved) {
		return -1;
	}
	if (a.is_reserved > b.is_reserved) {
		return 1;
	}
	return 0;
}
