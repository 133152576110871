import { NavLink } from "react-router-dom";
import styles from "./Components.module.scss";

export const HutPreview = (props) => {
	return (
		<article className={styles.preview}>
			<img src={props.data.image} alt="" />
			<h2>
				{props.data?.type} nr. {props.data.number}
			</h2>

			<h4>
				Pris: {props.data.price}kr.{" "}
				<NavLink to={"/details/" + props.data.id}>LÆS MERE</NavLink>
			</h4>
		</article>
	);
};
