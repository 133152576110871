import { NavLink, useParams } from "react-router-dom";
import { Modal } from "./modal";
import styles from "./Components.module.scss";

export const HutDetails = (props) => {
	const { id } = useParams();
	const [data] = props.data ? props?.data?.filter((item) => item.id == id) : [];
	console.log(data);
	return (
		<Modal headline={"Du kigger på " + data?.type + " nr. " + data?.number}>
			<div className={styles.detailsFlex}>
				<div className={styles.detailsInfo}>
					<h2>{data?.title}</h2>
					<h3>Beskrivelse:</h3>
					<div
						dangerouslySetInnerHTML={{ __html: data?.description }}
						className={styles.passedHtml}
					></div>
					<p className={styles.size}>
						(LxBxH): {data?.length}cm x {data?.width}cm x {data?.heigth}cm
					</p>
					<h5></h5>
				</div>
				<div className={styles.detailsLink}>
					<img src={data?.image} alt="" />
					{!data?.is_reserved ? (
						<NavLink
							to={"/details/" + id + "/reserver"}
							className={styles.link}
						>
							RESERVER
						</NavLink>
					) : (
						""
					)}
				</div>
			</div>
		</Modal>
	);
};
