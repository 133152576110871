import { useEffect, useState } from "react";
import styles from "./Components.module.scss";
import { Modal } from "./modal";

export const Success = (props) => {
	return (
		<Modal headline="Reservationen var successful">
			<div
				dangerouslySetInnerHTML={{
					__html: props?.data?.txt_after_reservation,
				}}
				className={styles.passedHtml + " " + styles.policy}
			></div>
		</Modal>
	);
};
