import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "./modal";
import styles from "./Components.module.scss";
import emailjs from "@emailjs/browser";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

export const Form = (props) => {
	const { id } = useParams();
	const [data] = props.data ? props?.data?.filter((item) => item.id == id) : [];
	const form = useRef();
	const [apiData, setApiData] = useState([])
	const [mailData, setMailData] = useState({})
	let navigate = useNavigate();

	const sendMail = () => {
		const mailServiceId = 'service_kj0xzn7'
		const mailUserId = 'user_FyX8kjCDQnKw2FYOuHAOj'
		const mailTemplates = [
			{ type: 'Admin', templateId: 'template_5p0k7ul' },
			{ type: 'User', templateId: 'template_g45pidd' }
		]

		mailTemplates.map(template => {
			emailjs.sendForm(mailServiceId,template.templateId, form.current, mailUserId)
				.then(result => {
					console.log(result.text)
				}, 
				error => {
					console.error(error.text)
				})	
		})
	}

	const submitForms = async (e) => {
		e.preventDefault();
		
		let selectedObj = apiData.find(item => {
			return item.id == id
		})

		if(!selectedObj.is_reserved) {
			try {
				const response = await axios.post(`https://hyttesalg.techcollege.dk/Umbraco/api/hytter/setreserved?id=${id}`)
				if(response.status === 200) {
					sendMail()
					navigate("/success")
				} else {
					alert("Unskyld der er sket en fejl. Prøv igen senere.")
				}
			}
			catch(err) {
				console.error(err)
			}
		} else {
			alert("Vi beklager. Denne vare er netop blevet reserveret")
		}
	}
	
	useEffect(() => {
		const getData = async () => {
			const result = await axios.get('https://hyttesalg.techcollege.dk/Umbraco/api/hytter/get')
			setApiData(result.data.items)
			setMailData(result.data)
		}
		getData()
	}, [setApiData]);

	return (
		<Modal
			headline={
				"Du er nu ved at reservere " + data?.type + " nr. " + data?.number
			}
		>
			{
				//!data?.is_reserved ? (
				<div className={styles.formGroup}>
					<div
						className={styles.passedHtml}
						dangerouslySetInnerHTML={{ __html: data?.description }}
					></div>
					<h3 className={styles.reservation}>
						Reservation af {data?.type} {data?.number}
					</h3>
					<form onSubmit={submitForms} className={styles.formBase} ref={form}>
						<input type="hidden" name="admin_email" defaultValue={`heka@techcollege.dk;${mailData.notification_email}`} />
						<input type="hidden" name="type" id="type" defaultValue={data?.type} />
						<input type="hidden" name="price" id="price" defaultValue={data?.price} />
						<input type="hidden" name="message" id="message" defaultValue={mailData.txt_after_reservation} />
						<input
							type="hidden"
							name="nummer"
							id="nummer"
							defaultValue={data?.number}
						/>
						<input type="hidden" name="dato" id="dato" value={new Date()} />
						<fieldset>
							<label htmlFor="user_name">Dit navn og efternavn: </label>
							<input
								type="text"
								name="user_name"
								id="user_name"
								placeholder="Navn"
								required
							/>
						</fieldset>
						<fieldset>
							<label htmlFor="Mobile">Dit telefon nr.: </label>
							<input
								type="tel"
								name="tlf"
								id="tlf"
								placeholder="+45 12 34 56 78"
								pattern="[0-9]{8}"
								required
							/>
						</fieldset>
						<fieldset>
							<label htmlFor="Email">Din emailadresse: </label>
							<input
								type="email"
								name="user_email"
								id="user_email"
								placeholder="example@email.com"
								required
							/>
						</fieldset>
						<fieldset>
							<h4>Pris: {data?.price}kr.</h4>
							<input type="submit" value="SEND" />
						</fieldset>
					</form>
				</div>
				//) : (
				//	<h2 className={styles.isReserved}>Denne {data?.type} er reserveret</h2>
				//)
			}
		</Modal>
	);
};
