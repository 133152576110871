import { useEffect, useState } from "react";
import styles from "./Components.module.scss";
import { Modal } from "./modal";

export const PolicyDetails = (props) => {
	return (
		<Modal headline="Læs dette, inden du bestiller" close={props.close}>
			<div
				dangerouslySetInnerHTML={{
					__html: props?.data?.txt_before_reservation,
				}}
				className={styles.passedHtml + " " + styles.policy}
			></div>
		</Modal>
	);
};
