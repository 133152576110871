import styles from "./Components.module.scss";

export const HutReserved = (props) => {
	return (
		<article className={styles.reservedHut}>
			<img src={props.data.image} alt="" />
			<h2>
				{props.data?.type} nr. {props.data.number}
			</h2>

			<h4>
				Pris: {props.data.price}kr. <a>Reserveret</a>
			</h4>
		</article>
	);
};
